import { queryKeys, XandrAdvertiser } from 'api';
import { AppLayout, Loader } from 'components';
import React, { useEffect, useMemo } from 'react';
import {
  Routes as BaseRoutes,
  Navigate,
  Route,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { useUrlSearchParams } from 'utils';

import { useKeycloak } from '@react-keycloak/web';
import { useQueryClient } from '@tanstack/react-query';

import { OffsetContainer } from 'components/offset-container';
import { Login } from 'features/login/Login';
import { useGetXandrAdvertiser } from 'features/select-advertiser/api/hooks';

import { useGetCampaignData } from './api/hooks';
import { appRoutes } from './appRoutes';
import { viewRoutes } from './constants';
import { PrivateRoute } from './PrivateRoute';

const Overview = React.lazy(() => import('features/Overview'));
const SearchAdvertiser = React.lazy(() => import('features/select-advertiser'));

const ViewComponent = React.lazy(() => import('features/view-component/ViewComponent'));

export const Routes = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { keycloak } = useKeycloak();
  const { country, campaignItemId, consumerSystemAdvertiserId } = useUrlSearchParams();
  const { pathname } = useLocation();

  const { isSuccess: isCampaignDataSuccess } = useGetCampaignData(keycloak.authenticated);
  const { mutate, data: xandrAdvertiserData } = useGetXandrAdvertiser();

  const xandrAdvertiserCacheData = queryClient.getQueryData<XandrAdvertiser>([
    queryKeys.xandrAdvertiser,
  ]);

  const hasToFetchAdvertiserData = useMemo(
    () =>
      consumerSystemAdvertiserId &&
      country &&
      !xandrAdvertiserData &&
      !xandrAdvertiserCacheData,
    [consumerSystemAdvertiserId, country, xandrAdvertiserCacheData, xandrAdvertiserData]
  );

  const hasToRedirectOnSelectAdvertiserPage = useMemo(
    () =>
      (!country || !campaignItemId) &&
      (!country || !consumerSystemAdvertiserId) &&
      pathname !== appRoutes.selectAdvertiser,
    [campaignItemId, consumerSystemAdvertiserId, country, pathname]
  );

  useEffect(() => {
    if (hasToFetchAdvertiserData) {
      mutate({ id: consumerSystemAdvertiserId, country });
    }
  }, [consumerSystemAdvertiserId, country, hasToFetchAdvertiserData, mutate]);

  useEffect(() => {
    if (hasToRedirectOnSelectAdvertiserPage) {
      navigate(appRoutes.selectAdvertiser);
    }
  }, [hasToRedirectOnSelectAdvertiserPage, navigate]);

  if (campaignItemId && !isCampaignDataSuccess && keycloak.authenticated) {
    return <Loader fullWidth width={100} />;
  }

  return (
    <BaseRoutes>
      <Route key={appRoutes.login} path={appRoutes.login} element={<Login />} />
      <Route element={<AppLayout />}>
        <Route
          path={appRoutes.overview}
          element={
            <PrivateRoute
              element={
                <OffsetContainer>
                  <Overview />
                </OffsetContainer>
              }
            />
          }
        />
        <Route
          path={appRoutes.selectAdvertiser}
          element={
            <PrivateRoute
              element={
                <OffsetContainer>
                  <SearchAdvertiser />
                </OffsetContainer>
              }
            />
          }
        />

        {viewRoutes.map((view) => (
          <Route
            key={view.path}
            path={view.path}
            element={<PrivateRoute element={<ViewComponent type={view.type} />} />}
          />
        ))}

        <Route
          key={'*'}
          path="*"
          element={
            <Navigate
              to={
                campaignItemId && country
                  ? `${appRoutes.overview}?campaign_item_id=${campaignItemId}&source=${country}`
                  : appRoutes.selectAdvertiser
              }
            />
          }
        />
      </Route>
    </BaseRoutes>
  );
};
