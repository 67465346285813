import React from 'react';

import styled from '@emotion/styled';
import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material';

import { themeColors } from 'theme';

export const ArrowTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: themeColors.lightBlack,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: themeColors.lightBlack,
    padding: '8px',
    borderRadius: '4px',
    color: themeColors.white,
  },
}));
