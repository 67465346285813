import React, { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import { Icon } from 'components/icon';

import { CreativesGridModals } from './CreativesGridModals';
import { useStyles } from './styles';

interface Props {
  rows: any[] | undefined;
  onDelete(ids: string[]): void;
  onDeselectAll(): void;
  onPublish(ids: string[]): void;
}

export const CreativesGridHeaderActions: React.FC<Props> = ({
  rows = [],
  onDelete,
  onDeselectAll,
  onPublish,
}) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
  const styles = useStyles();
  const buttons: {
    id: string;
    Icon: any;
    onClick(): void;
  }[] = useMemo(
    () => [
      {
        id: 'publish.creatives',
        Icon: <Icon name="activate" width={24} />,
        onClick: () => setIsPublishModalOpen(true),
      },
      {
        id: 'delete',
        Icon: <Icon name="delete" width={24} />,
        onClick: () => setIsDeleteModalOpen(true),
      },
    ],
    []
  );

  return rows?.length ? (
    <Box sx={styles.removeWrapper}>
      <CreativesGridModals
        isDeleteModalOpen={isDeleteModalOpen}
        isPublishModalOpen={isPublishModalOpen}
        selectedRows={rows}
        setIsDeleteModalOpen={setIsDeleteModalOpen}
        setIsPublishModalOpen={setIsPublishModalOpen}
        onDelete={onDelete}
        onPublish={onPublish}
        onDeselectAll={onDeselectAll}
      />
      <Box sx={styles.removeCounter}>
        <FormattedMessage id="grid.selected_rows" values={{ count: rows?.length }} />
        <Box ml={3} mr={2} sx={styles.verticalDivider} />
        <Box display="flex" gap="40px">
          {buttons.map(({ id, Icon, onClick }) => (
            <IconButton
              key={id}
              edge="end"
              sx={styles.removeBtnHeaderActions}
              size="large"
              onClick={onClick}
            >
              {Icon}
              <FormattedMessage
                id={id}
                values={{
                  countCreatives:
                    rows && rows.length === 1
                      ? `${rows.length} creative`
                      : `${rows.length} creatives`,
                }}
              />
            </IconButton>
          ))}
        </Box>
      </Box>
      <Button sx={styles.removeBtn} onClick={onDeselectAll}>
        <FormattedMessage id="deselect.all" />
      </Button>
    </Box>
  ) : null;
};
