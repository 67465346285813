import { SxProps } from '@mui/material';

import { themeColors } from 'theme/themeColors';

export const useStyles = () => ({
  gridCount: { fontSize: '18px', paddingLeft: '10px' },
  border: 'none',
  color: themeColors.black,
  '& a': {
    color: themeColors.black,
    textDecoration: 'none',
    overflow: 'hidden',
  },
  '& .MuiDataGrid-columnHeaderTitleContainer': {
    padding: 0,
    '&:focus': {
      outline: 'none',
    },
  },
  '& .MuiDataGrid-row': {
    '&:nth-of-type(even)': {
      backgroundColor: themeColors.grey20,
    },
    // '&.CreativeSetRow': {
    //   backgroundColor: themeColors.white,
    //   position: 'relative',
    //   '&:after': {
    //     content: `''`,
    //     position: 'absolute',
    //     top: '0',
    //     left: '21px',
    //     height: 36,
    //     width: 36,
    //     borderLeft: `2px solid ${themeColors.grey40}`,
    //     borderBottom: `2px solid ${themeColors.grey40}`,
    //     borderBottomLeftRadius: '3px',
    //   },
    //   '&:before': {
    //     content: `''`,
    //     position: 'absolute',
    //     top: '47%',
    //     left: '21px',
    //     transform: 'translate(0, -50%)',
    //     height: 73,
    //     width: 2,
    //     backgroundColor: themeColors.grey40,
    //   },
    //   '&:last-of-type, &:last-child': {
    //     '&:before': {
    //       top: '0',
    //       height: 69,
    //       borderBottomLeftRadius: '3px',
    //     },
    //   },
    //   '&.last-child': {
    //     '&:before': {
    //       top: '0',
    //       height: 69,
    //       borderBottomLeftRadius: '3px',
    //     },
    //   },
    //   '& div:nth-child(2), & div:nth-child(3)': {
    //     paddingLeft: '25px',
    //   },
    // },
    '& .MuiDataGrid-cell:first-of-type': {
      fontFamily: 'Proxima Nova Bold',
      outline: 'none',
    },
  },
  '& .MuiDataGrid-row.selected': {
    background: 'rgba(25, 118, 210, 0.08)',
  },
  '& .clickable:hover': {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  '& .MuiDataGrid-row:hover, & .MuiDataGrid-row.Mui-selected, & .MuiDataGrid-row.Mui-selected:hover':
    {
      cursor: 'pointer',

      '&:nth-of-type(even)': {
        backgroundColor: themeColors.grey20,
      },
      '&:nth-of-type(odd)': {
        backgroundColor: themeColors.white,
      },
    },
  '& .MuiDataGrid-row:hover': { backgroundColor: `${themeColors.purple20}!important` },
  '& .MuiDataGrid-columnHeaderTitle': {
    color: themeColors.grey120,
    fontFamily: 'Proxima Nova Bold',
    fontSize: 14,
  },

  '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-colCell:focus, & .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader--sortable:focus-within':
    {
      outline: 'none!important',
    },
  '& .MuiDataGrid-colCell-draggable': {
    justifyContent: 'space-between',
  },
  '& .MuiDataGrid-columnHeader': {
    outline: 'none',
    '&:hover': {
      outline: 'none',
    },
    '&:focus-within': {
      outline: 'none',
    },
    '&:last-of-type': {
      paddingRight: '32px',
    },
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
  },
  '& .MuiDataGrid-columnHeaders': {
    borderBottom: `2px solid ${themeColors.grey20}`,
  },
  '& .MuiDataGrid-cell': {
    borderBottom: 0,
  },
  '& .MuiDataGrid-cell$cell': {
    paddingLeft: '16px',
  },
  gridTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  titleAndSearch: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    mb: 1,
  } as SxProps,
  wrapper: {
    borderRadius: '3px',
    padding: '25px 34px 0',
    backgroundColor: themeColors.white,
    boxShadow: '0 2px 4px 0 rgba(163, 172, 178, 0.4)',
  },
  removeWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    backgroundColor: themeColors.purplePrimary,
    color: themeColors.white,
    borderRadius: '3px',
    paddingTop: '12px',
    paddingBottom: '12px',
    paddingRight: '32px',
    paddingLeft: '32px',
    height: 48,
  },
  removeCounter: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Proxima Nova Regular',
    fontSize: 16,
  },
  verticalDivider: {
    backgroundColor: '#efe4e4',
    width: '1px',
    height: 15,
    opacity: 0.5,
  },
  removeBtn: {
    color: themeColors.white,
    background: 'transparent',
    fontSize: 16,
    cursor: 'pointer',
    fontFamily: 'Proxima Nova Bold',
    '&:focus, &:active, &:hover': {
      backgroundColor: 'transparent',
    },
    '& svg': {
      fill: themeColors.white,
      marginLeft: '20px',
    },
  },
  removeBtnHeaderActions: {
    color: themeColors.white,
    display: 'flex',
    gap: '8px',
    background: 'transparent',
    fontSize: 16,
    cursor: 'pointer',
    fontFamily: 'Proxima Nova Bold',
    '&:focus, &:active, &:hover': {
      backgroundColor: 'transparent',
    },
    '& svg path': {
      fill: themeColors.white,
      marginRight: '8px',
      marginLeft: 0,
    },
  },
});
